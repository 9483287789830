@import '../utils/variable';
@import '../utils/mixin';

// these units must be in PX, because this will be the based of rem calculation
html.dynamicSizingEnabled {
  font-size: $font_size_tiny;

  // breakpoints are also defined in NbThemeContext.tsx, if you need to modify these, you have to update there as well
  @include screenSize(desktop) {
    font-size: $font_size_small;
  }

  @include screenSize(midres) {
    font-size: $font_size_medium;
  }

  @include screenSize(highres) {
    font-size: $font_size_normal;
  }

  @include screenSize(fullhd) {
    font-size: $font_size_normal;
  }
}

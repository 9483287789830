@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.list {
  height: rem(400);
  overflow-y: scroll;

  &IsEmpty {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: rem(14);
      line-height: rem(20);
      font-weight: 400;
      color: var(--text-primary-light);
    }
  }

  li:hover {
    background-color: var(--background-secondary);
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.widgetWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: var(--widget-background);
  border: rem(1) solid var(--border);
}

@mixin svgColor($clr) {
  svg {
    color: $clr;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  height: rem(38);
  background-color: var(--grey100);
  border-radius: rem(5) rem(5) 0 0;
  border-bottom: rem(1) solid var(--border);

  &Tabs {
  }

  &Actions {
  }
}

.widgetLink {
  &IsDefault {
    @include svgColor(var(--text-primary));
  }

  &IsGroup1 {
    @include svgColor(#3d9a00);
  }

  &IsGroup2 {
    @include svgColor(#7b7ad7);
  }
  &IsGroup3 {
    @include svgColor(#ffd0a3);
  }
  &IsGroup4 {
    @include svgColor(#1fffd5);
  }
  &IsGroup5 {
    @include svgColor(#2dff63);
  }
  &IsGroup6 {
    @include svgColor(#3390d3);
  }
  &IsGroup7 {
    @include svgColor(#d77a9b);
  }
  &IsGroup8 {
    @include svgColor(#ad7ad7);
  }
  &IsGroup9 {
    @include svgColor(#7ad7a2);
  }
}

.groupSelectorItem {
  display: flex;
  align-items: center;
  gap: rem(8);

  svg {
    width: rem(20);
    height: rem(20);
  }
}

.container {
  overflow-x: scroll;
  height: 100%;
  background-color: var(--widget-background);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.container {
  background: var(--widget-background-form);
  min-width: rem(300);
  padding: $padding_widget_inside;
}

.content {
  display: flex;
  flex-direction: column;
}

.buttonRow {
  margin-top: rem(10);
  margin-bottom: rem(16);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: rem(10);

  button {
    height: rem(50);
    white-space: pre-wrap;
    line-height: rem(20);
  }

  &SellButton {
  }

  &BuyButton {
  }
}

:global(.MuiButtonBase-root.MuiButton-root).buttonRowSellButton {
  background: var(--button-sell);
}
:global(.MuiButtonBase-root.MuiButton-root.Mui-disabled).buttonRowSellButton {
  background: rgba(var(--button-sell), 0.5);
}

:global(.MuiButtonBase-root.MuiButton-root).buttonRowBuyButton {
  background: var(--button-buy);
}
:global(.MuiButtonBase-root.MuiButton-root.Mui-disabled).buttonRowBuyButton {
  background: rgba(var(--button-buy), 0.5);
}
.switch {
  margin-left: rem(30);
  margin-right: rem(30);
  margin-bottom: rem(16);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &Column {
    max-width: 50%;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    gap: rem(4);
    margin-bottom: rem(10);
  }

  &IsDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.label {
  font-size: rem(12);
  line-height: rem(16);
  font-weight: 600;
}

.footer {
  display: flex;
  flex-direction: column;

  &Top {
    gap: rem(5);
    flex-direction: row;
    margin: 0 ($padding_widget_inside * -1);
    flex: 1;
    display: flex;
    background: var(--widget-divider);
    padding: rem(6);
    align-items: center;
    justify-content: center;
    border-top: rem(1) solid var(--border);
    border-bottom: rem(1) solid var(--border);

    span {
      font-size: rem(10);
      line-height: rem(16);
      color: var(--text-primary);
    }
  }

  &Bottom {
    padding: rem(10) 0 0;
    display: flex;

    &Row {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      span {
        font-size: rem(10);
        line-height: rem(16);
        color: var(--text-primary);
      }

      span:first-child {
        display: flex;
        flex: 1;
      }
      span:last-child {
        margin-right: rem(16);
      }
    }

    &Column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

.infoIcon {
  color: var(--info);
}

.downloadIcon {
  background-color: var(--background-secondary);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--widget-background-secondary);
  overflow-y: hidden;
  padding: $padding_widget_inside;
  gap: rem(10);
  min-width: rem(380);
  height: 100%;
}

.searchInput {
  max-width: rem(200);
}

.sectionsContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.portfolio {
  width: 100%;
  padding: rem(8);

  &Header {
    color: var(--primary);
    font-size: rem(18);
    font-weight: 600;
    line-height: rem(28);
  }

  &Content {
  }
}

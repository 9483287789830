@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    color: var(--text-primary);
    font-size: rem(12);
    line-height: rem(16);
  }

  &Label {
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    margin-right: rem(5);

    svg {
      height: rem(16);
      width: rem(16);
      color: var(--blue);
      margin-left: rem(5);
    }
  }

  &Value {
    font-weight: 600;

    &IsNumber {
      font-variant-numeric: tabular-nums;
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.delete {
  &Text {
    margin: 0 rem(-16);
    padding: rem(10) rem(16);
    background-color: var(--background-warning);
    font-size: rem(14);
    line-height: rem(20);

    a {
      color: var(--text-primary);
      text-decoration: underline;
    }
  }

  &Sure {
    margin-bottom: rem(8);
    font-weight: 600;
    line-height: rem(24);
    color: var(--text-primary);
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.popover {
  @include basePopover();
}

.header {
  display: flex;
  justify-content: space-between;
  background: var(--widget-background);
  border-bottom: rem(1) solid var(--border);

  &Tabs {
    height: rem(38);
  }

  &Select {
    height: rem(38);
    display: flex;
    align-items: center;
    padding-left: rem(10);

    :global(.MuiInputBase-root) {
      font-size: rem(12);
    }
  }

  &Actions {
    display: flex;
    max-height: rem(38);
    min-height: rem(38);

    svg {
      color: var(--text-primary);
    }
  }
}

.add {
  display: flex;
  flex-direction: column;
  //padding: rem(6);
  background-color: var(--popover-background);

  :global(.MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary) {
    color: var(--text-primary);
  }

  button {
    justify-content: left;
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
    text-align: left;
  }

  &Warning {
    display: flex;
    padding: rem(6);
    background-color: var(--background-warning);
    font-size: rem(14);
    line-height: rem(20);
    border-radius: rem(6);

    &Text {
      max-width: rem(160);
    }

    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: var(--warning);
      margin-right: rem(6);
    }
  }

  &Icon {
    display: flex;
    align-items: center;
    margin-right: rem(12);

    svg {
      color: var(--primary);
    }

    &IsDisabled {
      svg {
        color: var(--text-disabled);
      }
    }
  }
}

.settings {
  &Title {
    margin-bottom: rem(6);
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(20);
  }
}

:global(.Mui-disabled).addNewList {
  color: var(--text-disabled);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.nbWatchList {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;

  &Content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    width: 100%;
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.fillList {
  background-color: var(--widget-background-secondary);

  &Header {
    padding: $padding_widget_inside;
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(26);
  font-size: rem(14);
  font-weight: 500;
  line-height: rem(20);
  margin: rem(4) 0;
  border-radius: rem(4);

  &DragIndicator {
    cursor: move;
    display: flex;
    align-items: center;

    svg {
      height: rem(18);
    }
  }

  &:hover {
    background: var(--hover);
  }
}

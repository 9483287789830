@import '../../../styles/utils/variable';
@import '../../../styles/utils/function';
@import '../../../styles/utils/mixin';

.indexRowElement {
  display: flex;
  flex-direction: row;
  box-shadow: $general_boxShadow;
  background-color: var(--widget-background);
  padding: rem(10);
  gap: rem(10);
  border-radius: rem(2);
  align-items: flex-end;

  &Content {
    display: flex;
    flex-direction: column;
    gap: rem(6);
  }

  &ValueChange {
    display: flex;
    padding: rem(2) rem(6) rem(2);
    gap: rem(4);
    border-radius: rem(2);
    align-items: center;

    span {
      @include realtimePrice();
      color: var(--text-secondary);
      font-size: rem(12);
      line-height: rem(16);
      font-weight: 500;
      white-space: nowrap;
    }

    svg {
      color: var(--text-secondary);
      line-height: rem(16);
      font-weight: 500;
    }

    &IsPositive {
      background-color: var(--positive-change);
    }

    &IsNegative {
      background-color: var(--negative-change);
    }
  }

  &InstrumentName {
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 600;
    color: var(--text-primary);
  }

  &Value {
    @include realtimePrice();
    font-size: rem(12);
    line-height: rem(16);
    font-weight: 600;
    color: var(--text-primary);
  }
}

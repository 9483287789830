@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.orderConfirmRow {
  display: flex;
  justify-content: space-between;
  height: rem(32);
  align-items: center;
  padding-right: rem(15);
  padding-left: rem(15);

  span {
    color: var(--text-primary);
    font-size: rem(12);
    font-weight: 600;
    line-height: rem(16);
  }

  &IsDark {
    background-color: var(--modal-highlight);
  }

  &IsDivider {
    border-bottom: rem(1) solid var(--modal-divider);
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.nbOrderBookGrid {
  background-color: var(--widget-background);
  height: 100%;

  &Header {
    padding: $padding_widget_inside;
  }

  :global {
    .MuiDataGrid-cell {
      font-size: rem(12) !important;
    }

    .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
      outline: none;
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      font-size: rem(12);
    }
  }

  &Row {
    &IsEven {
      background-color: var(--grid-even-row-background);
    }
  }

  &Cell {
    &Box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem(26);
      width: rem(58);
      text-align: center;
      border-radius: rem(3);
      cursor: pointer;

      &IsHover {
        box-shadow: $cell_boxShadow;
        background-color: var(--background-secondary);
      }

      &IsBuy:hover {
        background-color: var(--sell);
        color: var(--text-secondary);
        box-shadow: $cell_boxShadow;
      }

      &IsSell:hover {
        background-color: var(--buy);
        color: var(--text-secondary);
      }
    }
  }
}

.actionIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $cell_boxShadow;
  text-align: center;
  border-radius: rem(3);
  height: rem(25);
  width: rem(25);

  &IsBuyPrice:hover {
    background-color: var(--sell);
    color: var(--text-secondary);
  }

  &IsSellPrice:hover {
    background-color: var(--buy);
    color: var(--text-secondary);
  }
}

:global(.MuiButtonBase-root.MuiIconButton-root).actionIcon {
  background-color: var(--background-secondary);
  border-radius: rem(3);
}

:global(.MuiButtonBase-root.MuiIconButton-root).actionIconIsBuyPrice:hover {
  background-color: var(--buy);
  color: var(--text-secondary);
}

:global(.MuiButtonBase-root.MuiIconButton-root).actionIconIsSellPrice:hover {
  background-color: var(--sell);
  color: var(--text-secondary);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.searchResultPaper {
  // TODO: remove important
  background-color: var(--search-result-background) !important;

  ul {
    :global(.MuiAutocomplete-option) {
      padding: 0;
    }

    :global(.Mui-focused.MuiAutocomplete-option) > div[data-is-selected='true'] {
      background: var(--search-result-selected-background);
    }

    :global(.Mui-focused.MuiAutocomplete-option) > div[data-is-selected='false'] {
      background: var(--search-result-selected-background);
    }
  }
}

.searchInput {
  flex-wrap: nowrap;
}

.noOption {
  display: flex;
  align-items: center;
  margin: rem(-16);
  padding: 0 rem(8);
  height: rem(48);
  background-color: var(--warning-background);
  color: var(--primary-text);
  font-weight: 700;
  line-height: rem(24);

  svg {
    width: rem(18);
    height: rem(18);
    color: var(--warning);
    margin-right: rem(12);
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.fullscreenLoader {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  display: grid;
  place-items: center;
  width: rem(80);
  height: rem(80);

  span {
    position: absolute;
    border: rem(5) solid var(--primary);
    border-radius: 50%;
    animation: ripple 1.8s ease-out infinite;
  }

  span:nth-child(2) {
    animation-delay: -0.6s;
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    width: 0;
    height: 0;
  }
  to {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

:global(.MuiSwitch-root).switch {
  padding: rem(4);

  :global(.MuiSwitch-track) {
    background-color: transparent;
    opacity: 1;
    height: rem(28);
    width: rem(50);
    border: rem(1) solid var(--white);
    border-radius: rem(9999);
  }

  :global(.MuiSwitch-thumb) {
    width: rem(24);
    height: rem(24);
  }

  :global(.Mui-checked) {
    transform: translateX(rem(22));
  }

  :global(.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track) {
    background-color: var(--white);
    opacity: 1;
  }

  :global(.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb) {
    background-color: var(--warning);
  }
}

:global(.MuiSwitch-root).switch {
  :global(.MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track) {
    opacity: 0.5;
  }

  :global(.MuiSwitch-switchBase.Mui-disabled.Mui-checked .MuiSwitch-thumb) {
    opacity: 0.5;
  }
}

:global(.MuiSwitch-switchBase).switchBase {
  padding: rem(6);
}

:global(.MuiSwitch-switchBase.Mui-checked) .switchTrack {
  background-color: var(--white);
}

// SMALL
:global(.MuiSwitch-sizeSmall.MuiSwitch-root).switch {
  padding: 0;
  width: rem(26);
  height: rem(16);

  :global(.MuiSwitch-track) {
    opacity: 1;
    height: rem(16);
    width: rem(26);
  }

  :global(.MuiSwitch-thumb) {
    width: rem(12);
    height: rem(12);
  }

  :global(.Mui-checked) {
    transform: translateX(rem(10));
  }
}
:global(.MuiSwitch-sizeSmall.MuiSwitch-root) :global(.MuiSwitch-switchBase).switchBase {
  padding: rem(2);
}

// SECONDARY color
:global(.MuiSwitch-root).switch {
  :global(.MuiSwitch-switchBase.MuiSwitch-colorSecondary + .MuiSwitch-track) {
    background: var(--grey300);
  }
  :global(.MuiSwitch-switchBase.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track) {
    background: var(--blue);
  }

  :global(.MuiSwitch-switchBase.MuiSwitch-colorSecondary .MuiSwitch-thumb) {
    background: var(--white);
  }
  :global(.MuiSwitch-switchBase.MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-thumb) {
    background: var(--white);
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.order {
  padding: rem(15);
  background-color: var(--modal-background);

  &Title {
    color: var(--modal-header-text);
    font-size: rem(18);
    font-weight: 700;
    line-height: rem(28);
  }

  &Header {
    display: flex;
    justify-content: space-between;

    svg {
      color: var(--modal-header-text);
    }
  }

  &Buttons {
    display: flex;
    justify-content: space-between;
    padding-top: rem(10);
  }
}

:global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary).orderRowSellButton {
  background: var(--button-sell);
  color: var(--text-secondary);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedPrimary).orderRowSellButton {
  color: var(--button-sell);
  border-color: var(--button-sell);
}

:global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary).orderRowBuyButton {
  background: var(--button-buy);
  color: var(--text-secondary);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedPrimary).orderRowBuyButton {
  color: var(--button-buy);
  border-color: var(--button-buy);
}

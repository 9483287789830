@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.nbButton {
  // primary
  &IsContainedPrimary {
    color: var(--button-primary-text);
    background-color: var(--button-primary);
  }
  &IsTextPrimary {
    color: var(--button-primary);
  }
  &IsOutlinedPrimary {
    color: var(--button-primary);
    border-color: var(--button-primary);
  }

  // secondary
  &IsContainedSecondary {
    border: rem(1) solid var(--button-secondary-contained-border);
    background: var(--button-secondary-contained);
    color: var(--button-secondary-contained-text);
  }
  &IsTextSecondary {
    color: var(--button-secondary);
  }
  &IsOutlinedSecondary {
    color: var(--button-secondary);
    border-color: var(--button-secondary);
  }

  // Emphasize
  &IsContainedEmphasize {
    background-color: var(--button-emphasize);
    color: var(--button-emphasize-text);
  }
  &IsTextEmphasize {
    color: var(--button-emphasize);
  }
  &IsOutlinedEmphasize {
    color: var(--button-emphasize);
    border-color: var(--button-emphasize);
  }

  :global(.MuiCircularProgress-root) {
    color: var(--white);
  }
}

:global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedEmphasize) {
  background: var(--button-emphasize);
  color: var(--button-emphasize-text);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-textEmphasize) {
  color: var(--button-emphasize);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedEmphasize) {
  color: var(--button-emphasize);
  border-color: var(--button-emphasize);
}

:global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary) {
  background: var(--button-primary);
  color: var(--button-primary-text);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary) {
  color: var(--button-primary);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedPrimary) {
  color: var(--button-primary);
  border-color: var(--button-primary);
}

:global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedSecondary) {
  background: var(--button-secondary-contained);
  color: var(--button-secondary-contained-text);
  border: rem(1) solid var(--button-secondary-contained-border);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-textSecondary) {
  color: var(--button-secondary);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedSecondary) {
  color: var(--button-secondary);
  border-color: var(--button-secondary);
}

// DISABLED STATES
:global(.Mui-disabled.MuiButtonBase-root.MuiButton-root).nbButtonIsContainedPrimary {
  opacity: 0.4;
}
:global(.Mui-disabled.MuiButtonBase-root.MuiButton-root).nbButtonIsTextPrimary {
  opacity: 0.4;
}
:global(.Mui-disabled.MuiButtonBase-root.MuiButton-root).nbButtonIsOutlinedPrimary {
  opacity: 0.4;
}
:global(.Mui-disabled.MuiButtonBase-root.MuiButton-root).nbButtonIsContainedSecondary {
  opacity: 0.4;
}
:global(.Mui-disabled.MuiButtonBase-root.MuiButton-root).nbButtonIsTextSecondary {
  color: rgba($color_black, 0.4);
}
:global(.Mui-disabled.MuiButtonBase-root.MuiButton-root).nbButtonIsOutlinedSecondary {
  opacity: 0.4;
}
:global(.Mui-disabled.MuiButtonBase-root.MuiButton-root.MuiButton-containedEmphasize) {
  opacity: 0.4;
}
:global(.Mui-disabled.MuiButtonBase-root.MuiButton-root.MuiButton-textEmphasize) {
  opacity: 0.4;
}
:global(.Mui-disabled.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedEmphasize) {
  opacity: 0.4;
}
:global(.MuiButton-root).nbButton {
  text-transform: none;
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.counter {
  position: absolute;
  top: 0;
  right: rem(-5);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: rem(18);
  height: rem(18);
  border-radius: 50%;
  background-color: var(--badge);
  color: var(--text-secondary);
  font-size: rem(12);
  font-weight: 700;
}

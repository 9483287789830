@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

:export {
  colorBackground: var(--tooltip-background);
  colorText: var(--tooltip-text);
  padding: rem(8);
  fontWeight: 500;
  fontSize: rem(12);
  lineHeight: rem(16);
}

.tooltipArrow {
  &::before {
    background: var(--tooltip-background) !important;
  }
}

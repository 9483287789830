@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.consent {
  &Wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: rem(20);
    background: var(--consent-background);
    border-radius: rem(6);

    @include screenSize(tablet) {
      top: auto;
      max-width: rem(700);
      margin: rem(55) rem(15);
    }
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
      color: var(--text-secondary);
      font-weight: 700;
      font-size: rem(18);
      line-height: rem(28);
    }

    svg {
      color: var(--text-secondary);
    }
  }

  &Text {
    position: relative;
    color: var(--text-secondary);
    font-weight: 500;
    font-size: rem(12);
    line-height: rem(16);

    &IsTeaser {
      @include screenSize(tablet) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }

    &Blur {
      display: none;

      @include screenSize(tablet) {
        display: inherit;
        position: absolute;
        bottom: 0;
        height: rem(45);
        width: 100%;
        background-image: linear-gradient(to bottom, transparent, var(--consent-background));
      }
    }
  }

  &Details {
    display: none;

    svg {
      display: flex;
    }

    @include screenSize(tablet) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.radios {
  display: flex;
  flex-direction: column;
  margin: rem(16) 0;
  border-top: rem(1) solid var(--white-alpha24);
  border-bottom: rem(1) solid var(--white-alpha24);

  @include screenSize(tablet) {
    flex-direction: row;
  }

  &Item {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding-left: rem(20);

    p {
      color: var(--text-secondary);
      font-weight: 700;
      line-height: rem(24);
    }

    @include screenSize(tablet) {
      justify-content: flex-start;
    }
  }

  &Item:first-child {
    border-bottom: rem(1) solid var(--white-alpha24);
    border-right: none;

    @include screenSize(tablet) {
      border-bottom: none;
      border-right: rem(1) solid var(--white-alpha24);
    }
  }

  &Switch {
    display: flex;
    align-items: center;
    padding-left: rem(16);
  }
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    margin: rem(8) 0;

    @include screenSize(tablet) {
      margin: 0;
    }
  }

  @include screenSize(tablet) {
    flex-direction: row;
  }

  &IsAccept {
    background-color: var(--warning);
  }
}

:global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary).actionsIsAccept {
  background-color: var(--warning);
}

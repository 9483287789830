@import '../../../styles/utils/variable';
@import '../../../styles/utils/function';

.selector {
  width: rem(150);
  background-color: var(--text-switch-background);
  border-radius: 50vh;
  border: rem(1) solid var(--text-switch-border);

  button {
    width: rem(42);
    height: rem(42);
    border: rem(1) solid var(--white);

    svg {
      width: rem(25);
      height: rem(25);
    }
  }

  :global(.MuiToggleButtonGroup-root) {
    gap: rem(8);
    padding: rem(2) 0;
  }

  :global(.MuiButtonBase-root) {
    padding: 0;
  }

  :global(.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped:not(:last-of-type)) {
    border-radius: 50%;
  }

  :global(.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped:first-of-type) {
    margin-left: rem(4);
  }

  :global(.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped:last-of-type) {
    border-radius: 50%;
  }

  :global(.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped:not(:first-of-type)) {
    border-left: rem(1) solid var(--white);
  }

  :global(.MuiButtonBase-root.MuiToggleButton-root.Mui-selected.MuiToggleButtonGroup-grouped) {
    background-color: var(--white);
  }
}

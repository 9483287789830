@import '../../../styles/utils/variable';
@import '../../../styles/utils/function';

.textSwitch {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: rem(33);
  border-radius: rem(16);
  border: rem(1) solid var(--text-switch-border);

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: rem(2);
    top: rem(2);
    right: rem(2);
    bottom: rem(2);
    background: var(--text-switch-background);
    border-radius: rem(16);
    transition: all 0.6s ease 0s;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: rem(18);
    width: 50%;
    min-width: rem(100);
    min-height: rem(33);
    transition: all 0.3s ease 0s;
  }

  button[class^='MuiButtonBase-root'] {
    position: relative;
    z-index: 3;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: rem(100);
    min-height: rem(33);
    width: 50%;
    border-radius: rem(16.5);
    overflow: hidden;
  }

  &IsLeftActive {
    &:after {
      left: 0;
      box-shadow: $general_boxShadow;
      background: var(--text-switch-active);
    }
  }

  &IsRightActive {
    &:after {
      left: 50%;
      box-shadow: $general_boxShadow;
      background: var(--text-switch-active);
    }
  }
}

.Text {
  font-size: rem(14);
  line-height: rem(20);
  font-weight: 600;
  color: var(--text-primary);

  &IsActive {
    color: var(--text-switch-active-text);
  }
}

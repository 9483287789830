.darkTheme {
  // common
  --primary: #a3b5c9;
  --white: #e5eaf0;
  --white-alpha24: rgba(255, 255, 255, 0.24);
  --positive-change: #028661;
  // TODO: dark?
  --positive-change-light: #0cb43f;
  --negative-change: #ef0039;
  --sell: #e72222;
  --buy: #028661;
  --border: #354661;
  --background: #08203d;
  --background-secondary: #5c7999;
  --background-warning: #feebcb;
  --background-success: #c6f6d5;
  --background-info: #bee3f8;
  --background-error: #fed7d7;
  --warning: #ff6130;
  --error: #ef0039;
  --success: #028661;
  --info: #2870ed;
  --badge: #b40536;
  --link: #65b8ff;
  --shadow: transparent;
  --blue: #2870ed;
  --hover: #294b7a;
  --hover-secondary: #f5f8fb;
  --purple500: #721c7a;
  --purple100: #8e2998;

  // grey inverse TODO: dark?
  --grey: #e2e8f0;
  --grey100: #011839;
  --grey200: #011839;
  --grey300: #a3b5c9;
  --grey400: #7591ab;
  --grey500: #718096;

  --grey300-alpha24: rgba(163, 181, 201, 0.24);

  // widget
  --widget-background: #011839;
  --widget-background-secondary: #011839;
  --widget-background-form: #061d40;
  --widget-divider: #011839;
  --widget-content: #344660;
  --widget-header-icon: #e5eaf0;

  // header
  --header-background: #001b41;
  --header-text: #e5eaf0;
  --header-border: #ffffff66;
  --header-hover: #ffffff33;
  --header-hover-text: #e5eaf0;

  // tooltip
  --tooltip-background: #02132c;
  --tooltip-text: #ffffff;

  // text
  --text-primary: #ffffff;
  --text-primary-light: #7591ab;
  --text-secondary: #e5eaf0;
  --text-disabled: #e5eaf0;

  // modal
  --modal-header-text: #e5eaf0;
  --modal-background: #1a365d;
  --modal-divider: #344660;
  --modal-highlight: #2d415b;

  // text switch
  --text-switch-active: #5c7999;
  --text-switch-background: #011839;
  --text-switch-active-text: #ffffff;
  --text-switch-border: #354661;

  // grid
  --grid-background: #011839;
  --grid-header-background: #08203d;
  --grid-odd-row-background: #011839;
  --grid-even-row-background: #08203d;
  --grid-positive-row-background: #028661;
  --grid-negative-row-background: #c21600;
  --grid-sub-content: #344660;

  // buttons
  --button-primary: #2870ed;
  --button-primary-text: #ffffff;
  --button-emphasize: #3e6499;
  --button-emphasize-text: #ffffff;
  --button-secondary: #ffffff;
  --button-secondary-text: #e5eaf0;
  --button-secondary-contained: transparent;
  --button-secondary-contained-text: #ffffff;
  --button-secondary-contained-border: #3f4f67;
  --button-sell: #e53e3e;
  --button-buy: #028661;

  // inputs
  --input-background: #011839;
  --input-androrment-background: #011839;
  --input-border: #354661;
  --input-icon: #a3b5c9;

  // tabs
  --tab-selected-background: #2870ed;
  --tab-selected-text: #ffffff;
  --tab-text: #a3b5c9;

  // popover
  --popover-background: #1a365d;
  --popover-content-border: #274877;
  --popover-content: #294b7a;
  --popover-border: #274877;

  // instrument search
  --search-input: #1a365d;
  --search-result-selected: #2870ed;
  --search-result-selected-background: #08203d;
  --search-result-background: #011839;

  // consent
  --consent-background: #1a365d;

  // snackbar
  --snackbar-content-pinned: #ffffff;
  --snackbar-content: #303030;

  // date picker
  --date-picker-content: #011839;

  // menu
  --menu-border: #556987;
  --menu-item-icon: #a3b5c9;
  --menu-item: #344660;
  --menu-item-active: #011839;
  --menu-item-active-text: #ffffff;
  --menu-submenu-active: #011839;
  --menu-subitem-hover: #5c7999;
  --menu-item-collapsed-hover: #344660;
  --menu-collapsed-popover: #344660;

  // category
  --certificate: #00780f;
  --compensationNote: #e6a127;
  --share: #ff6905;
  --investmentUnit: #00cc9a;
  --option: #5578aa;
  --currency: #1c4fca;
  --future: #9747ff;

  // shadow
  --menu_boxShadow: 0 rem(4) rem(6) rem(-1) rgba(0, 0, 0, 0.1), 0 rem(2) rem(4) rem(-1) rgba(0, 0, 0, 0.06);
  --widget_boxShadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.1), 0 rem(1) rem(2) rgba(0, 0, 0, 0.06);
  --cell_boxShadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.1), 0 rem(1) rem(2) rgba(0, 0, 0, 0.06);
  --button_light_boxShadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.1), 0 rem(1) rem(2) rgba(0, 0, 0, 0.06);
  --general_boxShadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.1), 0 rem(1) rem(2) rgba(0, 0, 0, 0.06);
  --notes_inner_boxShadow: inset 0 rem(4) rem(9) rgba(0, 0, 0, 0.15);
  --popover_boxShadow: 0 rem(4) rem(6) rem(-1) rgba(0, 0, 0, 0.1), 0 rem(2) rem(4) rem(-1) rgba(0, 0, 0, 0.06);
  --portfolio_widget_boxShadow: 0 rem(2) rem(3) 0 rgba(0, 0, 0, 0.2);
}

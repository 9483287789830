@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.item {
  $root: &;
  display: flex;
  justify-content: space-between;
  height: rem(54);
  width: 100%;
  padding: 0 rem(16);
  background-color: var(--search-result-background);
  border-bottom: rem(1) solid var(--border);
  cursor: pointer;

  &IsSelected {
    background-color: var(--search-result-selected-background);
    color: var(--search-result-selected);

    svg {
      color: var(--search-result-selected);
    }

    #{$root}Body {
      span {
        &:not(:last-child):after {
          background-color: var(--search-result-selected);
        }
      }
    }
  }

  &:hover {
    background-color: var(--search-result-selected-background);
    color: var(--search-result-selected);

    #{$root}Body {
      span {
        &:not(:last-child):after {
          background-color: var(--search-result-selected);
        }
      }
    }

    #{$root}Actions {
      svg {
        color: var(--search-result-selected);
      }
    }
  }

  &Content {
    display: flex;
  }

  &Logo {
    margin-right: rem(16);
  }

  &Body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      position: relative;
      margin-right: rem(20);

      &:not(:last-child):after {
        content: '';
        position: absolute;
        top: rem(8);
        right: rem(-10);
        width: rem(4);
        height: rem(4);
        border-radius: 50%;
        background-color: var(--text-primary);
      }
    }
  }

  &Name {
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(20);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &Details {
    display: flex;
    flex-wrap: nowrap;
    font-size: rem(12);
    font-weight: 500;
    line-height: rem(20);
    overflow: hidden;
  }

  &Actions {
    display: flex;
    align-items: center;

    svg {
      color: var(--modal-header-text);
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/mixin';

.legends {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: rem(200);
  overflow-y: scroll;
  width: 100%;
}

.legend {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: rem(5) rem(10);
  cursor: pointer;
  width: 100%;
  gap: rem(16);

  &:hover {
    background: var(--hover);
  }

  &Color {
    position: absolute;
    left: 0;
    top: rem(2);
    width: rem(4);
    bottom: rem(2);
  }

  &Name {
    font-size: rem(12);
    font-weight: 500;
    line-height: rem(16);
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Value {
    @include realtimePrice;
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: rem(12);
    font-weight: 500;
    line-height: rem(16);
    text-align: right;
  }

  &IsLoan {
    margin-top: rem(10);
    background: var(--hover);
  }
}

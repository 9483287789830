@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.nbAutocomplete {
  width: 100%;
  background-color: var(--input-background);
  border-radius: rem(6);
  border: rem(1) solid var(--input-border);

  :global(.MuiAutocomplete-endAdornment) {
    display: none;
  }

  &IsSmall {
    label {
      top: rem(-8);
    }

    input {
      margin: 0 rem(12);
    }

    :global(
        .MuiInputBase-root.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl
      ) {
      padding: 0 0 0 rem(6);

      svg {
        color: var(--input-icon);
      }

      input {
        margin-left: 0;
        font-size: rem(14);
        padding-left: rem(2);
        color: var(--text-primary);
      }
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.nbOpenPositions {
  background-color: var(--grid-background);

  &Grid {
    &Row {
      &IsEven {
        background-color: var(--grid-even-row-background);
        button:first-of-type {
          color: var(--text-primary);
        }
      }

      &IsOdd {
        button:first-of-type {
          color: var(--text-primary);
        }
      }

      &IsSub {
        background-color: var(--grid-sub-content);
      }
    }
  }
}

.subGrid {
  background-color: var(--grid-sub-content);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.grid {
  &Row {
    &IsLow {
      background-color: var(--grid-negative-row-background);
    }

    &IsHigh {
      background-color: var(--grid-positive-row-background);
    }
  }
}

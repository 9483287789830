@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

$intervalLineHeight: rem(10);

.header {
  display: flex;
  flex-direction: column;
  background-color: var(--widget-content);
  padding: rem(10) rem(15) rem(15);
  gap: rem(10);

  &TitleRow {
    display: flex;
    flex-direction: row;

    &Text {
      font-size: rem(18);
      line-height: rem(28);
      color: var(--text-primary);
      font-weight: 700;
    }

    &Category {
      margin-right: rem(10);
    }
  }

  &Content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(10);

    &LastPrice {
      font-size: rem(24);
      line-height: rem(32);
      color: var(--text-primary);
      font-weight: 700;
      font-variant-numeric: tabular-nums;

      &Circle {
        background-color: var(--sell);
        height: rem(9);
        width: rem(9);
        border-radius: 50%;
      }
    }

    &Column {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: rem(5);
    }

    &ChangeText {
      font-size: rem(12);
      line-height: rem(16);
      font-weight: 500;
      font-variant-numeric: tabular-nums;

      &IsPositive {
        color: var(--positive-change);
      }

      &IsNegative {
        color: var(--negative-change);
      }
    }
  }
}

.bar {
  background-color: var(--grey300-alpha24);
  height: rem(4);
  margin-bottom: $intervalLineHeight;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;

  &Interval {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: rem(-4);
    background-color: var(--sell);
    align-items: center;

    &Line {
      width: rem(1);
      background-color: var(--text-primary);
      right: rem(0);
      height: $intervalLineHeight;
    }

    span {
      position: absolute;
      top: $intervalLineHeight;
      color: var(--text-primary);
      font-size: rem(10);
      line-height: rem(16);
    }

    &IsStart {
      left: rem(0);
    }

    &IsEnd {
      left: 100%;
    }
  }

  &Circle {
    position: absolute;
    z-index: 100;
    width: rem(15);
    height: rem(15);
    border-radius: 50%;
    border: rem(1) solid var(--white);
    box-sizing: content-box;
    transition: all 0.2s ease-in;

    &IsRed {
      z-index: 102;
      background-color: var(--sell);
      left: 50%;

      span {
        z-index: 101;
        position: absolute;
        border: rem(1) solid var(--sell);
        border-radius: 50%;
        height: rem(15);
        width: rem(15);
        animation: ripple 2s ease-out infinite;
        transform-origin: center;
      }

      span:nth-child(2) {
        animation-delay: -1s;
      }
    }

    &IsBlue {
      background-color: var(--blue);
      left: 60%;
    }
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    scale: 1;
  }
  to {
    opacity: 0;
    scale: 2;
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.note {
  padding: rem(15);
  background-color: var(--modal-background);

  &Title {
    color: var(--modal-header-text);
    font-size: rem(18);
    font-weight: 700;
    line-height: rem(28);
  }

  &Header {
    display: flex;
    justify-content: space-between;

    svg {
      color: var(--modal-header-text);
    }
  }

  &Content {
    fieldset {
      border: none;
      box-shadow: $notes_inner_boxShadow;
      background-color: var(--input-background);
    }

    textarea {
      z-index: 1;

      background-color: var(--input-background);
      color: var(--primary-text);
    }

    &Save {
      display: flex;
      justify-content: flex-end;
      padding-top: rem(8);
      color: var(--text-primary-light);
      font-size: rem(12);
      line-height: rem(24);
    }
  }

  &Buttons {
    display: flex;
    justify-content: space-between;
    padding-top: rem(16);
  }
}

@import '../../../styles/utils/variable';
@import '../../../styles/utils/function';

$minHeight: rem(170);

.hint {
  min-height: $minHeight;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem(12);
  padding: rem(20);

  &Dark {
    background-color: var(--purple500);
  }

  &Light {
    background-color: var(--purple100);
  }

  h4 {
    color: var(--text-secondary);
    font-weight: 800;
    line-height: rem(28);
  }

  button {
    font-size: rem(18);
    font-weight: 600;
    line-height: rem(28);
  }
}

.reveal {
  min-height: $minHeight;
  padding: rem(12);
  display: flex;
  flex-direction: column;
  background-color: var(--widget-background);
  border: rem(1) solid var(--border);

  &Button {
    width: rem(90);
  }

  p {
    color: var(--text-primary);
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: rem(40);
    margin-bottom: rem(25);

    &Change {
      display: flex;
      flex-direction: column;

      span {
        margin: 0;
        font-size: rem(12);
        line-height: rem(16);
      }

      span:first-of-type {
        font-weight: 600;
      }
    }
  }

  &Title {
    font-weight: 800;
    line-height: rem(24);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &ArrowIcon {
    width: rem(40);
    height: rem(40);
    margin-right: rem(12);
  }

  &InfoIcon {
    width: rem(20);
    height: rem(20);
    color: var(--primary);
    margin: 0 rem(10);
  }

  &Content {
  }

  &Row {
    display: flex;
    justify-content: space-between;
  }

  &Item {
    display: flex;
    justify-content: space-between;
    width: 50%;
    padding: 0 rem(6);

    &Title {
      margin: rem(4) 0;
      font-size: rem(12);
      line-height: rem(21);
    }

    &Price {
      margin: rem(4) 0;
      font-size: rem(12);
      font-weight: 700;
      line-height: rem(21);
    }
  }

  &Link {
    display: flex;
    justify-content: flex-end;
    padding-top: rem(16);

    a {
      color: var(--link);
      font-size: rem(12);
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.isUp {
  color: var(--positive-change);
}

.isDown {
  color: var(--negative-change);
}

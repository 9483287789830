.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &Name {
    flex: 1;
  }

  &Switch {
  }
}

.dataGrid {
  background-color: var(--grid-background);
  :global {
    .MuiDataGrid-cell {
      font-size: rem(12) !important;
      color: var(--text-primary);
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      font-size: rem(12);
      color: var(--text-primary);
    }

    .MuiDataGrid-pinnedColumns {
      background-color: var(--grid-background);
    }

    .MuiDataGrid-pinnedColumnHeaders {
      background-color: var(--grid-background);
    }
  }

  &Row {
    &IsEven {
      background-color: var(--grid-even-row-background);
    }

    &IsOdd {
      background-color: var(--grid-odd-row-background);
    }
  }
}

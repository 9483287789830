@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &Main {
    display: flex;
    padding-left: $menu_width;
    height: 100%;

    &IsCollapsed {
      padding-left: rem(56);
      transition: $menu_close_animation;
    }
  }
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: $frame_padding;
  overflow-x: hidden;
}

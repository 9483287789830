@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.search {
  display: flex;
  flex-direction: column;
  width: rem(670);
  height: rem(400);
  background-color: var(--modal-background);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(60);
  padding: 0 rem(16);
  width: 100%;

  h2 {
    margin: 0;
    color: var(--modal-header-text);
    font-size: rem(18);
    font-weight: 700;
    line-height: rem(28);
  }

  svg {
    color: var(--modal-header-text);
  }
}

.input {
  position: relative;
  background-color: var(--search-input);
  padding: rem(16);

  input {
  }
}

.listWrapper {
  overflow-y: scroll;
}

.list {
  height: 100%;
}

.progressBar {
  top: rem(-4);
}

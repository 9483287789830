@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.menu {
  &Row {
    display: flex;
    align-items: center;
    padding: rem(5) rem(2);
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
    cursor: pointer;

    &:hover {
      background-color: var(--hover);
    }

    svg {
      color: var(--primary);
      margin-right: rem(10);
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.orderConfirm {
  width: rem(540);
  margin: 0 rem(-15);
  padding-top: rem(15);
  padding-bottom: rem(15);
  border-top: rem(1) solid var(--modal-divider);
  border-bottom: rem(1) solid var(--modal-divider);
  background-color: var(--modal-background);

  &Link {
    svg {
      width: rem(12);
      height: rem(12);
    }
  }
}

:global(.MuiButtonBase-root.MuiButton-root).orderConfirmLink {
  margin-top: rem(16);
  margin-left: rem(10);
  font-size: rem(12);
  line-height: rem(16);
  color: var(--link);
}

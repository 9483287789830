@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

$size_header_element: rem(44);

.nbDatePicker {
  width: 100%;

  &IsSmall {
    label {
      transform: translate(rem(12), rem(6)) scale(1);
      font-style: normal;
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(20);
    }

    :global {
      label.MuiFormLabel-filled,
      label.MuiInputLabel-shrink {
        transform: translate(rem(14), rem(-9)) scale(0.75);
        left: 0;
        top: 0;
      }

      .MuiInputBase-root {
        background-color: var(--input-background);
        padding: 0;
        border: rem(1) solid var(--input-border);
        border-radius: rem(6);

        svg {
          color: var(--input-icon);
        }
      }
    }

    input {
      padding: rem(5) rem(12);
      font-style: normal;
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(20);
    }
  }

  &Adornment {
    background-color: var(--input-androrment-background);
    padding: rem(6) rem(12);
    color: var(--text-primary);
    font-size: rem(14);

    &IsStart {
      border-radius: rem(6) 0 0 rem(6);
      border-right: rem(1) solid var();
    }

    &IsEnd {
      border-radius: 0 rem(6) rem(6) 0;
      border-left: rem(1) solid var(--input-border);
    }
  }
}

:global(.MuiPaper-root.MuiPickersPopper-paper) {
  background-color: var(--popover-background);
}

:global(.MuiButtonBase-root.MuiPickersDay-root) {
  border-radius: rem(4);
  background-color: var(--date-picker-content);
  font-size: rem(14);
  line-height: rem(14);
  font-weight: 500;
  color: var(--text-primary);
}

:global(.MuiButtonBase-root.MuiPickersDay-root:hover) {
  background-color: var(--hover);
}

:global(.MuiPickersDay-dayOutsideMonth) {
  opacity: 15%;
}

:global(.MuiButtonBase-root.MuiPickersDay-root.Mui-selected) {
  background-color: var(--blue);
  font-size: rem(14);
  line-height: rem(14);
  font-weight: 500;
  color: var(--text-secondary);
}

:global(.MuiTypography-root.MuiDayCalendar-weekDayLabel) {
  font-size: rem(14);
  line-height: rem(14);
  font-weight: 500;
  color: var(--text-primary);
}

:global(.MuiPickersYear-root) {
  button {
    border-radius: rem(4);
    background-color: var(--date-picker-content);
    font-size: rem(14);
    line-height: rem(14);
    font-weight: 500;
    color: var(--text-primary);
  }

  button:hover {
    background-color: var(--hover);
  }
}

:global(.MuiPickersCalendarHeader-root) {
  display: flex;
  align-items: center;
  justify-items: center;
}

:global(.MuiPickersArrowSwitcher-root) {
  display: inline-flex;
}

:global(.MuiPickersCalendarHeader-label) {
  display: flex;
  align-items: center;
  font-size: rem(16);
  line-height: rem(16);
  font-weight: 700;
  color: var(--text-primary);
  white-space: nowrap;
  background-color: var(--date-picker-content);

  height: $size_header_element;
  padding: rem(7) rem(12);
  border-radius: rem(6);
}

:global(.css-31ca4x-MuiPickersFadeTransitionGroup-root) {
  position: absolute !important;
  justify-content: center;
  display: flex !important;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

:global(.MuiPickersArrowSwitcher-root) {
  button:first-of-type {
    position: absolute;
    left: 10px;
  }

  svg {
    color: var(--text-primary);
  }
}

:global(.MuiButtonBase-root.MuiIconButton-root.MuiPickersArrowSwitcher-button) {
  background-color: var(--date-picker-content);
  height: $size_header_element;
  width: $size_header_element;
}

:global(.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-switchViewButton) {
  visibility: hidden;
}

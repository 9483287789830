@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.menu {
  $menuRoot: &;
  position: fixed;
  z-index: 1001;
  width: $menu_width;
  flex: 0 0 $menu_width;
  height: 100vh;
  padding: 0 0 rem(10) rem(10);
  transition: none;

  div[class^='MuiPaper-root'] {
    background-color: transparent;
    box-shadow: none;
  }

  div[class^='MuiAccordionSummary-content.Mui-expanded'] {
    margin: 0;
  }

  &Element {
    margin: rem(6) 0;

    :global(.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded) {
      min-height: 0;
      margin: 0;
    }

    :global(.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters) {
      min-height: rem(46);
      padding: rem(0) rem(16);
    }
  }

  &IsCollapsed {
    width: rem(56);
    flex: 0 0 rem(56);
    transition: $menu_close_animation;

    #{$menuRoot}Item {
      :global(.MuiAccordionSummary-expandIconWrapper) {
        display: none;
      }

      :global(.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters) {
        margin: rem(12) 0;
      }

      &Icon {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
        border: rem(1) solid var(--menu-border);
        border-radius: rem(6);

        #{$menuRoot}ItemIcon {
          background: var(--menu-item-collapsed-hover);

          svg {
            fill: var(--white);
          }
        }

        .collapsedMenuItem {
          display: block;
          position: absolute;
          border-radius: 0 0 rem(6) rem(6);
          padding: 0;
          left: rem(35);
          top: 0;
          width: rem(235);
          z-index: 10;

          &Header {
            height: rem(44);
            display: flex;
            justify-content: left;
            align-items: center;
            padding: 0 rem(20) 0 rem(38);
            border-radius: 0 rem(6) 0 0;

            background: var(--menu-item-collapsed-hover);
            color: var(--white);
            font-style: normal;
            font-weight: 700;
            font-size: rem(16);
            line-height: rem(24);
          }

          :global(.MuiAccordionSummary-root) {
            background: var(--menu-collapsed-popover);
            box-shadow: $menu_boxShadow;
            margin-left: rem(21);
          }

          ul {
            padding: 0;
          }
        }
      }
    }
  }

  .collapsedMenuItem {
    display: none;
  }

  &Item {
    max-height: rem(44);

    &IsActive {
      // important is needed to overwrite Mui accordion background
      min-height: rem(46) !important;
      background-color: var(--menu-item-active) !important;
      border-radius: rem(5) !important;
      border: rem(1) solid var(--menu-border) !important;
    }

    a {
      display: flex;
      align-items: center;
      width: 100%;
      color: var(--text-primary);
      font-weight: 700;
      line-height: rem(24);
    }

    svg {
      fill: var(--menu-item-icon);
      color: var(--menu-item-icon);
      stroke: var(--menu-item-icon);
    }

    &Icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(44);
      height: rem(44);
      padding: rem(10);
      margin-right: rem(12);
      background-color: var(--menu-item);
      border-radius: rem(5);
      box-shadow: $menu_boxShadow;

      &IsActive {
        background-color: var(--menu-item-active);
        box-shadow: none;
      }
    }

    &Text {
    }

    :global(.MuiAccordionSummary-content) {
      font-weight: 700;
    }

    &More {
      display: flex;
      align-items: center;
      position: absolute;
      right: rem(12);

      svg {
        color: var(--text-primary);
      }
    }

    &IsActive {
      a {
        color: var(--white);
        font-weight: 700;
        line-height: rem(24);
        border-radius: rem(5);
      }

      svg {
        fill: var(--white);
        stroke: var(--white);
      }

      :global(.MuiAccordionSummary-content) {
        color: var(--white);
        font-weight: 700;
        line-height: rem(24);
      }
    }

    :global(.MuiAccordionSummary-content) {
      display: flex;
      align-content: center;
      align-items: center;
    }
  }

  .toggleButton {
    padding: rem(12) rem(12) rem(12) rem(17);
    margin: 0;

    svg {
      color: var(--white);
    }

    &IsCollapsed {
      padding-left: rem(4);
      transition: $menu_close_animation;
    }
  }
}

.submenu {
  display: flex;
  flex-direction: column;
  flex: 1;

  &Item {
    flex: 1;
    margin: rem(2) rem(-16);
    padding: rem(10) rem(32);
    color: var(--text-primary);
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
    border-radius: rem(6);
    border: rem(1) solid transparent;

    &:hover {
      color: var(--menu-subitem-hover);
      border: rem(1) solid var(--menu-border);
    }

    &IsCollapsed {
      margin: rem(2) rem(-10);
    }

    &IsActive {
      color: var(--menu-item-active-text);
      border: rem(1) solid var(--menu-border);
      background: var(--menu-submenu-active);

      &:hover {
        color: var(--menu-item-active-text);
      }
    }
  }
}

.logout {
  position: absolute;
  bottom: rem(20);
  left: rem(26);

  svg {
    display: flex;
    align-items: center;
  }

  :global(.MuiButtonBase-root) {
    padding: 0;
    color: var(--text-primary);
    font-size: rem(16);
    font-weight: 700;
    line-height: rem(24);
  }

  :global(.MuiButtonBase-root):hover {
    background-color: transparent;
  }

  &Button {
    display: flex;
    align-items: center;
  }

  :global(.MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary) {
    color: var(--text-primary);
  }

  :hover {
    .logoutIcon {
      background-color: var(--menu-item);
      border-radius: rem(5);
      box-shadow: $menu_boxShadow;
    }
  }

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(44);
    height: rem(44);
    margin-right: rem(12);

    path {
      stroke: var(--text-primary);
    }
  }

  &IsCollapsed {
    left: rem(8);
    width: rem(56);
  }
}

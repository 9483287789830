@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles//utils/mixin';

.header {
  background-color: var(--grid-header-background);
  padding: rem(10) rem(15) rem(10);

  span {
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(28);
    color: var(--text-primary);
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  &List {
    flex: 1;
    min-width: rem(200);

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: rem(10) rem(15) rem(10);
      display: flex;
      background-color: var(--grid-even-row-background);
    }

    span {
      white-space: nowrap;
    }

    li:nth-child(odd) {
      background-color: var(--grid-odd-even-background);
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.header {
  display: flex;
  justify-content: space-between;
  padding: rem(6) rem(12);

  h4 {
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(20);
  }

  &Buttons {
    display: flex;

    button {
      margin-left: rem(10);
      box-shadow: none;
    }
  }

  &ButtonIsSelected {
    box-shadow: $button_light_boxShadow;
  }

  :global(.MuiButtonBase-root.MuiButton-root.MuiButton-textSecondary) {
    color: var(--text-primary);
  }
}

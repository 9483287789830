@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);

  &Bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(20);
    height: rem(20);
    font-size: rem(8);
    font-weight: 600;
    line-height: rem(20);
    border-radius: rem(3);

    &Is0 {
      background-color: seagreen;
    }

    &Is1 {
      background-color: #721c7a;
    }

    &Is2 {
      background-color: #2a6ce4;
    }

    &Is3 {
      background-color: #4a5568;
    }

    &Is4 {
      background-color: #d77a9b;
    }

    &Is5 {
      background-color: peru;
    }

    &Is6 {
      background-color: #2dff63;
    }

    &Is7 {
      background-color: cyan;
    }

    &Is8 {
      background-color: crimson;
    }

    &Is9 {
      background-color: hotpink;
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

:export {
  colorBackgroundBuy: var(--buy);
  colorBackgroundSell: var(--sell);
  colorText: var(--text-secondary);
  padding: rem(8);
  fontWeight: 500;
  fontSize: rem(12);
  lineHeight: rem(16);
}

.tooltipArrowLeft {
  &::before {
    background: var(--buy) !important;
  }
}

.tooltipArrowRight {
  &::before {
    background: var(--sell) !important;
  }
}

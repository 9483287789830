@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.cash {
  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(10);
    padding: 0 rem(6);
    border-bottom: rem(1) solid var(--popover-content-border);

    svg {
      color: var(--modal-header-text);
    }

    h4 {
      font-size: rem(14);
      font-weight: 600;
      line-height: rem(20);
    }
  }

  &Buttonish {
    display: flex;
    align-items: center;
    padding: rem(4) rem(8);
    border: rem(1) solid var(--grey);
    border-radius: rem(6);

    span {
      display: flex;
      align-items: center;
      margin-right: rem(8);

      svg {
        width: rem(12);
        height: rem(12);
        color: var(--blue);
      }
    }
  }

  &Fiat {
    padding-top: rem(12);
  }

  &Row {
    @include realtimePrice;

    display: flex;
    justify-content: space-between;

    &IsBordered {
      border-bottom: rem(1) solid var(--popover-content-border);
    }

    p {
      display: flex;
      margin: rem(6) 0;
      font-size: rem(12);
      font-weight: 600;
      line-height: rem(16);
    }

    &Light {
      @include realtimePrice;

      color: var(--text-primary-light);
    }

    &Left {
      display: flex;
      align-items: center;

      &Flag {
        padding-right: rem(12);

        img {
          width: rem(40);
          height: rem(40);
        }
      }

      p {
        margin: rem(3) 0;
      }

      &Fiat {
        display: flex;
        flex-direction: column;
        margin: rem(2) 0;
        text-transform: uppercase;
      }
    }

    &Right {
      @include realtimePrice;

      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin: rem(3) 0;
      }

      &Icon {
        padding-left: rem(10);
        cursor: pointer;

        svg {
          width: rem(14);
          height: rem(14);
          color: var(--blue);
        }
      }
    }
  }

  &Content {
    padding: rem(6) rem(6);
    background-color: var(--popover-content);
    border-radius: rem(6);
    box-shadow: 0 rem(1) rem(3) rgba(var(--shadow), 0.1), 0 rem(1) rem(2) rgba(var(--shadow), 0.06);
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.edit {
  width: rem(430);
  background-color: var(--modal-background);

  &Header {
    display: flex;
    justify-content: space-between;
    padding: rem(15);
    margin-bottom: rem(10);
    color: var(--modal-header-text);
    border-bottom: rem(1) solid var(--modal-divider);

    h4 {
      font-size: rem(18);
      font-weight: 700;
      line-height: rem(28);
    }

    svg {
      color: var(--modal-header-text);
    }
  }

  &Search {
    padding: 0 rem(10);
  }

  &Content {
    background-color: var(--widget-background-form);
    padding: rem(15);
    margin-top: rem(10);
  }

  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(8) 0;

    p {
      flex: 35%;
      font-size: rem(12);
      font-weight: 600;
      line-height: rem(16);
      margin: 0;
    }
  }

  &SentimentWrapper {
    flex: 100%;
  }

  &CharCount {
    color: var(--text-primary-light);
    font-weight: 600;
    text-align: right;
  }

  &Note {
    background-color: var(--input-background);
    box-shadow: $notes_inner_boxShadow;
  }

  &Info {
    display: flex;
    align-items: center;
    gap: rem(12);
    margin: rem(8) 0;
    padding: rem(12) rem(16);
    background-color: var(--background-info);
    border-radius: rem(6);

    svg {
      color: var(--info);
    }

    p {
      font-size: rem(14);
      line-height: rem(20);
    }
  }

  &ManagementButton {
    display: flex;
    justify-content: flex-end;

    button {
      color: var(--text-primary);
    }
  }

  &Buttons {
    display: flex;
    justify-content: space-between;
    padding: rem(15);
  }
}

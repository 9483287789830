@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.tradingPage {
  width: 100%;
  display: flex;
  flex-direction: column;

  height: calc(100vh - $header_height - $frame_padding - $frame_padding);
}

@import './src/styles/utils/variable';
@import './src/styles/utils/function';

@mixin screenSize($media) {
  @if $media == fullhd {
    @media screen and (min-width: $resolution_fullHd) {
      @content;
    }
  } @else if $media == highres {
    @media screen and (min-width: $resolution_highRes) {
      @content;
    }
  } @else if $media == midres {
    @media screen and (min-width: $resolution_midRes) {
      @content;
    }
  } @else if $media == desktop {
    @media screen and (min-width: $resolution_desktop) {
      @content;
    }
  } @else if $media == tablet {
    @media screen and (min-width: map-get($breakpoint, 'tablet_1')) {
      @content;
    }
  } @else if $media == onMobile {
    @media screen and (max-width: map-get($breakpoint, 'tablet_1')) {
      @content;
    }
  }
}

@mixin basePopover() {
  // !important is necessary because sibling MUI elements cannot be overriden using global
  border: rem(1) solid var(--popover-border) !important;
  background-color: var(--popover-background) !important;
  border-radius: rem(6);
  padding: rem(10);
}

@mixin setColumnsPopover {
  @include basePopover;

  &Button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(14);
    border-bottom: rem(1) solid var(--popover-content-border);

    p {
      color: var(--error);
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(20);
    }

    button {
      width: rem(40);
      height: rem(40);
    }

    svg {
      width: rem(20);
      height: rem(20);
      color: var(--error);
    }
  }

  &Title {
    color: var(--text-primary);
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(20);
  }

  :global(.MuiSwitch-switchBase.Mui-disabled.Mui-checked .MuiSwitch-thumb) {
    // important was needed as 5 classes and global was not enough to override mui this time
    opacity: 1 !important;
  }
}

@mixin realtimePrice() {
  font-variant-numeric: tabular-nums;
}

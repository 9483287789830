@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.accounts {
  margin-top: rem(6);

  &Header {
    :global(.MuiButtonBase-root) {
      justify-content: start;
      text-transform: uppercase;

      &:hover {
        background-color: var(--color-white);
        color: var(--text-primary);
      }
    }

    :global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedSecondary) {
      background-color: var(--button-emphasize);
    }

    button {
      width: 100%;
    }
  }

  .accountWrapper:not(:last-child) {
    margin-bottom: rem(6);
    padding-bottom: rem(6);
    border-bottom: rem(1) solid var(--popover-content-border);
  }

  :global(.MuiButtonBase-root) {
    justify-content: start;
    text-transform: uppercase;
    box-shadow: $button_light_boxShadow;

    &:hover {
      color: var(--primary);
      background-color: var(--hover);
      box-shadow: $button_light_boxShadow;
    }
  }

  :global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedSecondary) {
    background-color: var(--button-emphasize);
  }
}

.account {
  padding: rem(6);
  background-color: var(--popover-content);
  border: rem(1) solid var(--popover-content-border);
  border-radius: rem(6);

  &Name {
    margin: rem(6) 0;
    color: var(--text-primary);
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(20);
    text-transform: uppercase;
  }

  &Id {
    margin: rem(6) 0;
    color: var(--text-primary-light);
    font-size: rem(12);
    font-weight: 700;
    line-height: rem(16);
  }

  &Buttons {
    display: flex;
    flex-direction: column;

    button {
      margin: rem(3) 0;
    }
  }
}

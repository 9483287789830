@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.homePage {
  padding: 0;
  width: 100%;

  @media screen and (min-height: rem(800)) {
    height: calc(100vh - $header_height - $frame_padding - $frame_padding);
  }
}

.container {
  display: grid;
  grid-template-columns: rem(400) rem(540) minmax(250px, 1fr);
  grid-template-rows: rem(118) 1fr rem(360);
  gap: $padding_widget_between $padding_widget_between;
  grid-auto-flow: row;
  grid-template-areas:
    'indexRow indexRow indexRow'
    'portfolioContainer watchlistContainer watchlistContainer'
    'portfolioContainer marketCarouselContainer marketHintContainer';
  width: 100%;
  height: 100%;

  @include screenSize(highres) {
    grid-template-columns: 2fr 1.5fr max-content;
  }
}

.indexRow {
  grid-area: indexRow;
  height: rem(118);
}

.portfolioContainer {
  grid-area: portfolioContainer;
  height: 100%;
}

.watchlistContainer {
  grid-area: watchlistContainer;
  min-height: rem(250);
}

.marketCarouselContainer {
  grid-area: marketCarouselContainer;
  display: flex;
}

.marketHintContainer {
  grid-area: marketHintContainer;
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    height: 50%;
  }
}

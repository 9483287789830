@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.formControlLabel {
  :global(.MuiFormControlLabel-label) {
    font-size: rem(12);
    line-height: rem(16);
    color: var(--text-primary);
    font-weight: 600;
    margin-left: rem(10);
  }
}

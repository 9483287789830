@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.openOrders {
}

.popover {
  @include setColumnsPopover;
}

.header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 0;

  button {
    margin: 0 rem(20);
    padding: rem(4);

    svg {
      color: var(--modal-header-text);
    }
  }

  &More {
    // sadly necessary important to have the button positioned absolutely
    position: absolute !important;
    right: rem(-6);
    top: rem(2);
    z-index: 2;
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.grid {
  &Row {
    &IsEven {
      background-color: var(--grid-even-row-background);
      button:first-of-type {
        color: var(--text-primary);
      }
    }

    &IsOdd {
      button:first-of-type {
        color: var(--text-primary);
      }
    }
  }
}

.subContent {
  padding: rem(10);
  background-color: var(--grid-sub-content);

  &Title {
    font-size: rem(14);
    font-weight: 700;
    color: var(--text-primry);
    line-height: rem(20);
  }

  &Row {
    display: flex;

    p {
      margin: 0;
      width: rem(230);
      font-size: rem(12);
      font-weight: 500;
      line-height: rem(20);
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.loginView {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: $color_white;

  @include screenSize(tablet) {
    flex-direction: row;
  }
}

.login {
  position: relative;
  height: 100vh;

  &MobileHeader {
    display: block;
    height: rem(170);
    width: 100%;

    img {
      width: 100%;
    }

    @include screenSize(tablet) {
      display: none;
    }
  }

  &Section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: rem(30) rem(12);
    border-radius: rem(15) rem(15) 0 0;
    background-color: $color_midLightGrey;

    @include screenSize(tablet) {
      flex: 50%;
      padding: rem(60) rem(40);
      background-color: $color_white;
      border-radius: 0;
      align-items: center;
    }
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include screenSize(tablet) {
      width: 100%;
      max-width: rem(380);
    }
  }

  &Title {
    display: flex;
    color: $color_anthracite;
    font-style: normal;
    font-weight: 700;
    font-size: rem(30);
    line-height: rem(36);
    margin-bottom: rem(40);
  }

  &Content {
    :global(.MuiFormControl-root) {
      margin: rem(4) 0;
    }

    @include screenSize(tablet) {
      width: 100%;
      max-width: rem(380);
    }
  }

  &Input {
  }

  &Button {
    margin: rem(32) 0;

    button {
      width: 100%;
    }
  }

  &Help {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: $color_blue;
    font-weight: 500;

    a {
      line-height: rem(24);
    }
  }

  &Register {
    display: block;
    padding-top: rem(35);
    text-align: center;

    a {
      color: $color_blue;
      font-weight: 500;
      text-decoration: underline;
    }

    @include screenSize(tablet) {
      display: none;
    }
  }

  &Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: rem(11);
    font-weight: 400;
    line-height: rem(16);

    @include screenSize(tablet) {
      flex-direction: row;
      font-size: rem(16);
      font-weight: 500;
      line-height: rem(24);

      width: 100%;
      max-width: rem(380);
    }

    &Erste {
      color: $color_lightSteel;
      line-height: rem(24);
      text-align: center;
    }

    &Links {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: rem(16);

      @include screenSize(tablet) {
        padding-top: 0;
      }
    }

    &Link {
      text-align: center;

      &:not(:last-child) {
        margin: 0 rem(10);
      }

      a {
        color: $color_blue;
        font-weight: 500;
      }
    }
  }
}

.registration {
  position: relative;
  color: $color_anthracite;

  &Section {
    display: none;
    flex: 50%;
    height: 100vh;
    padding: rem(75) rem(100) 0;
    background-color: $color_paleBlue;

    @include screenSize(tablet) {
      display: block;
    }
  }

  &Title {
    color: $color_anthracite;
    font-size: rem(30);
    font-weight: 700;
    line-height: rem(36);
  }

  &Subtitle {
    line-height: rem(24);
  }

  &List {
    padding: rem(20) 0;
    line-height: rem(24);

    &Item {
      display: flex;
      align-items: center;
      padding: rem(8) 0;

      svg {
        display: flex;
        margin-right: rem(10);
        color: $color_blue;
      }
    }
  }

  &Buttons {
    :first-of-type {
      margin-right: rem(20);
    }
  }

  &Photo {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 50%;
    font-size: 0;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles//utils//mixin';

.nbTextField {
  width: 100%;

  &IsSmall {
    label {
      transform: translate(rem(12), rem(6)) scale(1);
      font-style: normal;
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(20);
    }

    :global {
      label.MuiFormLabel-filled,
      label.MuiInputLabel-shrink {
        transform: translate(rem(14), rem(-9)) scale(0.75);
        left: 0;
        top: 0;
      }

      .MuiInputBase-root {
        background-color: var(--input-background);
        border: rem(1) solid var(--input-border);
        padding: 0;
        border-radius: rem(6);
      }
    }

    input {
      padding: rem(5) rem(12);
      font-style: normal;
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(20);
    }
  }

  &Adornment {
    background-color: var(--input-androrment-background);
    padding: rem(6) rem(12);
    color: var(--text-primary);
    font-size: rem(14);

    &IsStart {
      border-radius: rem(6) 0 0 rem(6);
      border-right: rem(1) solid var(--input-border);
    }

    &IsEnd {
      border-radius: 0 rem(6) rem(6) 0;
      border-left: rem(1) solid var(--input-border);
    }
  }
}

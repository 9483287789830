@import '../../../styles/utils/variable';
@import '../../../styles/utils/function';

.snackbar {
  padding-top: rem(12);
  padding-bottom: rem(12);
  padding-right: rem(18);
  padding-left: rem(18);
  border-radius: rem(4);
  display: flex;
  width: rem(356);

  &IsInfo {
    background-color: var(--background-info);
    border-bottom-width: rem(4);
    border-bottom-style: solid;
    border-bottom-color: var(--info);
    div {
      color: var(--snackbar-content);
    }

    svg {
      color: var(--info);
    }

    &IsPinned {
      background-color: var(--info);
      div,
      svg {
        color: var(--snackbar-content-pinned);
      }
    }
  }

  &IsError {
    background-color: var(--background-error);
    border-bottom-width: rem(4);
    border-bottom-style: solid;
    border-bottom-color: var(--error);
    div {
      color: var(--snackbar-content);
    }

    svg {
      color: var(--error);
    }

    &IsPinned {
      background-color: var(--error);
      div,
      svg {
        color: var(--snackbar-content-pinned);
      }
    }
  }

  &IsSuccess {
    background-color: var(--background-success);
    border-bottom-width: rem(4);
    border-bottom-style: solid;
    border-bottom-color: var(--success);
    div {
      color: var(--snackbar-content);
    }

    svg {
      color: var(--success);
    }

    &IsPinned {
      background-color: var(--success) div, svg {
        color: var(--snackbar-content-pinned);
      }
    }
  }

  &IsWarning {
    background-color: var(--background-warning);
    border-bottom-width: rem(4);
    border-bottom-style: solid;
    border-bottom-color: var(--warning);
    div {
      color: var(--snackbar-content);
    }

    svg {
      color: var(--warning);
    }

    &IsPinned {
      background-color: var(--warning) div, svg {
        color: var(--snackbar-content-pinned);
      }
    }
  }

  .content {
    margin-left: rem(13);
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &Title {
    font-size: rem(16);
    line-height: rem(24);
    font-weight: 700;
  }

  &Message {
    font-size: rem(16);
    line-height: rem(24);
    font-weight: 400;
    white-space: pre-wrap;
  }

  &Close {
    height: rem(12) !important;
    width: rem(12) !important;
  }
}

.ciq-night {
  .chartContainer {
    background-color: var(--widget-background);
  }

  .ciq-nav {
    background-color: var(--widget-background);
    border: rem(1) solid var(--border);
  }

  .ciq-menu {
    color: var(--text-primary);
  }

  .cq-chart-title {
    background-color: var(--widget-content);
  }
}

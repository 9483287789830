@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

:export {
  certificateColor: map-get($instrumentCategoryColors, 'certificate');
  compensationNoteColor: map-get($instrumentCategoryColors, 'compensationNote');
  shareColor: map-get($instrumentCategoryColors, 'share');
  investmentUnitColor: map-get($instrumentCategoryColors, 'investmentUnit');
  optionColor: map-get($instrumentCategoryColors, 'option');
  currencyColor: map-get($instrumentCategoryColors, 'currency');
  futureColor: map-get($instrumentCategoryColors, 'future');
  unknownColor: $color_pink;
  ersteColor: $color_blue;
}

.composition {
  background-color: var(--widget-background);
  border-radius: rem(2);
  box-shadow: $portfolio_widget_boxShadow;

  &IsHomePage {
    height: 100%;

    .compositionContent {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &MainHeader {
    display: flex;
    align-items: center;
    height: rem(38);
    padding: 0 rem(12);
    background-color: var(--widget-background-secondary);
    color: var(--widget-header-icon);
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(20);
    border-bottom: rem(1) solid var(--widget-divider);
  }

  &Loader {
    padding: rem(40);
  }

  &Content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(10);
  }
}

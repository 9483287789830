@use 'sass:color';
@import './function';

// FONTS
$font: (
  'inter': 'Inter',
);

// TYPO
$typo: (
  'font': map-get($font, 'inter'),
  'size': 16,
  'weight': 400,
);

// BREAKPOINTS
$breakpoint: (
  'desktop_4': 3840px,
  'desktop_3': 2560px,
  'desktop_2': 1920px,
  'desktop_1': 1650px,
  'laptop_2': 1366px,
  'laptop_1': 1024px,
  'tablet_2': 960px,
  'tablet_1': 768px,
  'mobile_3': 640px,
  'mobile_2': 480px,
  'mobile_1': 320px,
);
$resolution_fullHd: map-get($breakpoint, 'desktop_2');
$resolution_highRes: map-get($breakpoint, 'desktop_1');
$resolution_midRes: map-get($breakpoint, 'laptop_2');
$resolution_desktop: map-get($breakpoint, 'laptop_1');

// COLORS
$color_textPrimary: #303030;
$color_primary: #2870ed;
$color_secondary: #ffffff;
$color_error: #ef0039;
$color_warning: #ff6130;
$color_info: #a3b5c9;
$color_success: #0cb43f;

// TODO: replace color
$color_textPrimaryDark: #ffffff;
$color_primaryDark: #2870ed;
$color_secondaryDark: #ffffff;
$color_errorDark: #ef0039;
$color_warningDark: #ff6130;
$color_infoDark: #a3b5c9;
$color_successDark: #0cb43f;

// TODO: remove all color below

$color_white: #ffffff;

$color_grey: #e2e8f0;
$color_midLightGrey: #f5f8fb; // gray100
$color_lightGrey: #e4eaf0; // gray200
$color_midGrey: #a3b5c9; // erste gray/300
$color_middleGrey: #7591ab; // erste gray/400
$color_grey500: #718096; // erste gray/500

// input androment
$color_darkishGrey: #edf2f7;
// TODO: remove?? header only
$color_darkGrey: #5c7999;

//text-primary
$color_anthracite: #303030;
// tooltip
$color_darkSoul: #02132c;
// TODO: nem tudom
$color_lightSteel: #4a5568;
//TODO: modal header, merge with textPrimary???
$color_strongSteel: #2d3748;
// shadow color
$color_black: #111111;
// buttomPrimary & accentPrim
$color_blue: #2870ed;
// TODO: pala-blue merge grey200?
$color_paleBlue: #e5eaf0;
// remove
$color_teal: #02a3a4;
//positiveChange & buttonBuy
$color_forest: #028661;
//TODO: success background? (snackbar)
$color_paleGreen: #c6f6d5;
// positiveChangeLight
$color_apple: #0cb43f;
// TODO: gridPositiveRow merge success background?
$color_greenLight: #9ae6b4; // green/200
// warning background
$color_orangeLight: #feebcb;
// warning
$color_orange: #ff6130;
// merge with warning background???
$color_orangeMid: #f2a91e66;
// error
$color_redMain: #ef0039;
// negativeChange
$color_red: #e72222;
$color_redLight: #feb2b2; // red/200
// button sell
$color_redRadical: #ff3e6c;
// icon badge
$color_raspberryPink: #ef3e6d;
// merge button sell
$color_pink: #eb4c79;
// delete
$color_aubergine: #721c7a;
// delete
$color_stone: #245375;
// link
$color_skyBlue: #65b8ff;

// TODO: remove

$font_size_tiny: 10px;
$font_size_small: 12px;
$font_size_medium: 14px;
$font_size_normal: 16px;

$instrumentCategoryColors: (
  'certificate': #00780f,
  'compensationNote': #e6a127,
  'share': #ff6905,
  'investmentUnit': #00cc9a,
  'option': #5578aa,
  'currency': #1c4fca,
  'future': #9747ff,
);

// BOX SHADOWS
$menu_boxShadow: 0 rem(4) rem(6) rem(-1) rgba(0, 0, 0, 0.1), 0 rem(2) rem(4) rem(-1) rgba(0, 0, 0, 0.06);
$widget_boxShadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.1), 0 rem(1) rem(2) rgba(0, 0, 0, 0.06);
$cell_boxShadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.1), 0 rem(1) rem(2) rgba(0, 0, 0, 0.06);
$button_light_boxShadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.1), 0 rem(1) rem(2) rgba(0, 0, 0, 0.06);
$general_boxShadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.1), 0 rem(1) rem(2) rgba(0, 0, 0, 0.06);
$notes_inner_boxShadow: inset 0 rem(4) rem(9) rgba(0, 0, 0, 0.15);
$popover_boxShadow: 0 rem(4) rem(6) rem(-1) rgba(0, 0, 0, 0.1), 0 rem(2) rem(4) rem(-1) rgba(0, 0, 0, 0.06);
$portfolio_widget_boxShadow: 0 rem(2) rem(3) 0 rgba(0, 0, 0, 0.2);
$carousel_boxShadow: 0 rem(1) rem(2) 0 rgba(0, 0, 0, 0.06), 0 rem(1) rem(3) 0 rgba(0, 0, 0, 0.1);

// WIDTH/HEIGHT/PADDING CONSTS
$menu_width: rem(240);
$header_height: rem(62);
$frame_padding: rem(10);

$padding_widget_inside: rem(10);
$padding_widget_between: rem(8);

// ANIMATIONS
$menu_close_animation: all 0.3s ease-in-out;

@import '../../../styles/utils/variable';
@import '../../../styles/utils/function';

.indexRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: rem(10);
  background-color: var(--widget-background-form);
  border: rem(1) solid var(--border);

  &Charts {
    display: flex;
    flex-direction: row;
    gap: rem(10);
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  &Arrows {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      color: var(--primary);
    }

    :global(.MuiButtonBase-root.MuiIconButton-root.Mui-disabled) {
      color: var(--primary);
      opacity: 0.4;
    }
  }
}

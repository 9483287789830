@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.nbWatchListGrid {
  width: 100%;
  height: 100%;

  &Dot {
    display: inline-block;
    width: rem(8);
    height: rem(8);
    margin-right: rem(6);
    border-radius: 50%;

    &IsRed {
      background-color: var(--error);
    }

    &IsOrange {
      background-color: var(--warning);
    }

    &IsGreen {
      background-color: var(--success);
    }
  }

  &Cell {
    &Box {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem(26);
      width: rem(58);
      background-color: var(--background-secondary);
      text-align: center;
      border-radius: rem(3);
      box-shadow: $cell_boxShadow;

      &IsBuyPrice:hover {
        background-color: var(--sell);
        color: var(--text-secondary);
      }

      &IsSellPrice:hover {
        background-color: var(--buy);
        color: var(--text-secondary);
      }
    }

    &IsHigher {
      color: var(--positive-change);
    }

    &IsLower {
      color: var(--negative-change);
    }
  }
}

.actionIcon {
  font-size: rem(40);

  svg {
    color: var(--primary);
  }
}

.popover {
  @include basePopover();
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.header {
  display: flex;
  justify-content: space-between;
  height: $header_height;
  padding: rem(12) rem(24) rem(12) rem(50);
  background-color: var(--header-background);

  :global(.MuiButtonBase-root) {
    text-transform: uppercase;
  }

  &ButtonAllAccounts {
    &Icon {
      margin: rem(10) 0 rem(10) rem(10);
    }
  }

  &ButtonAllAccountsContent {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &IsCollapsedMenu {
    padding-left: rem(36);
    transition: $menu_close_animation;
  }
}

.logo {
  display: flex;
  flex: 10;
  margin-left: rem(30);

  :first-child {
    margin-right: rem(6);
  }

  :last-child {
    margin-top: rem(-8);
  }
}

.main {
  display: flex;
  flex: 70;
  padding: 0 rem(95);

  svg {
    color: var(--header-text);
  }

  button {
    color: var(--header-text);
    border-color: var(--header-border);
    margin-right: rem(20);
    font-size: rem(12);
    font-weight: 700;
    line-height: rem(16);

    svg:first-child {
      margin-right: rem(14);
    }

    &:hover {
      border-color: var(--header-white);
    }
  }

  &Item {
    @include realtimePrice;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 rem(14);
    font-size: rem(12);
    font-weight: 700;
    color: var(--header-text);

    p {
      margin: 0;
      font-size: rem(12);
      line-height: rem(16);
    }

    &IsButton {
      border-radius: rem(6);
      cursor: pointer;

      &:hover {
        background-color: var(--header-hover);
        color: var(--header-hover-text);
      }
    }
  }
}

.actions {
  display: flex;
  flex: 20;
  justify-content: flex-end;
  gap: rem(26);

  button {
    padding: 0;
  }

  svg {
    margin: 0;
    color: var(--header-text);
  }
}

.action {
  &WithNoti {
    display: flex;
    position: relative;
  }
}

.popover {
  @include basePopover;
  width: rem(260);
  padding: rem(6);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.exchange {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &Header {
    display: flex;
    justify-content: space-between;
    padding: 0 rem(6);

    svg {
      color: var(--modal-header-text);
    }

    &Title {
      margin: 0;
      font-size: rem(14);
      font-weight: 600;
      line-height: rem(20);
    }
  }

  &Content {
    padding: rem(8);
    background-color: var(--popover-content);
    border-radius: rem(6);
    box-shadow: 0 rem(1) rem(3) rgba(var(--shadow), 0.1), 0 rem(1) rem(2) rgba(var(--shadow), 0.06);
  }

  &Title {
    display: flex;
    align-items: center;

    p {
      font-size: rem(12);
      font-weight: 700;
      line-height: rem(16);
    }
  }

  &Flag {
    width: rem(40);
    height: rem(40);
    margin-right: rem(12);
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(4) 0;
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.listRow {
  display: flex;
  padding: rem(10) rem(15);
  gap: rem(20);
  border-top: 1px solid var(--border);
  transition: all 0.3s ease-in-out;

  &IsOpened {
    background-color: var(--background-secondary);
  }

  &Title {
    font-size: rem(12);
    line-height: rem(16);
    font-weight: 600;
    color: var(--text-primary);
    display: inline;

    &IsRead {
      font-weight: 500;
    }

    &More {
      margin-left: rem(4);
      background: var(--background-secondary);
      height: rem(5);
      border-radius: rem(2);
      vertical-align: middle;
    }
  }

  &Date {
    font-size: rem(10);
    line-height: rem(14);
    font-weight: 400;
    color: var(--text-primary-light);
  }

  &Message {
    margin-top: rem(10);
    font-size: rem(12);
    line-height: rem(16);
    font-weight: 500;
    color: var(--text-primary);
    display: flex;
    flex: 1;
    position: relative;
  }

  &Content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.readIndicator {
  width: rem(12);
  height: rem(12);
  border-radius: 50%;
  background-color: var(--blue);

  &IsRead {
    background-color: var(--grey300);
  }
}

.infoIcon {
  color: var(--info);
}

.warningIcon {
  color: var(--warning);
}

.errorIcon {
  color: var(--error);
}

.successIcon {
  color: var(--success);
}

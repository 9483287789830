@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.chart {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  transition: width 0s ease-in-out;

  &InnerText {
    position: absolute;
    font-weight: 700;
    text-align: center;
    font-size: rem(16);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  path {
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  &IsFullWidth {
    width: 100%;
    transition: width 0.3s ease-in-out;
  }

  &IsAnimated {
    path {
      transition: all 0.3s ease-in-out;
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.portfolioCompositionGrid {
  background-color: $color_midLightGrey;
  :global(.MuiDataGrid-root) {
    background-color: $color_midLightGrey;
  }

  :global(.MuiDataGrid-pinnedColumnHeaders) {
    background-color: $color_midLightGrey;
  }

  :global(.MuiDataGrid-pinnedColumns) {
    background-color: $color_midLightGrey;

    svg {
      color: $color_anthracite;
    }
  }
}

.gridContainer {
  height: rem(400);
}

.groupTickerToggleContainer {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.groupTickerToggleItem {
  position: absolute;
  top: rem(-5);
  bottom: rem(-5);
  left: rem(-10);
  width: rem(4);
}

.groupTicker {
  &RowContainer {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &RowItem {
    position: absolute;
    left: rem(-10);
    top: rem(-8);
    width: rem(6);
    height: rem(36);
  }

  &Instrument {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &AccountNumber {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: rem(12);
    color: var(--text-primary-light);
  }
}

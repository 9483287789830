@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

cq-context {
  position: relative;
  height: calc(100%);

  span,
  div,
  td,
  select,
  cq-item {
    font-size: rem(12);
    box-sizing: content-box;
  }

  cq-clickable {
    font-size: rem(12);
  }

  cq-dialog {
    max-width: 90vw !important;
  }
}

.NNChartArea {
  bottom: 0px;
}

.rangeSelect {
  display: flex;
  justify-content: center;

  &Icon {
    margin-top: rem(7);
  }
}

.ersteLogo {
  position: absolute;
  right: rem(70);
  top: rem(10);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.orderWidget {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;

  &Content {
    overflow-y: scroll;
    padding: $padding_widget_inside;
    background-color: var(--widget-background-form);
    margin-top: rem(-8);
  }

  &Footer {
    border-top: rem(1) solid var(--border);
    display: flex;
    position: absolute;
    bottom: 0;
    justify-content: flex-end;
    padding: $padding_widget_inside rem(15) $padding_widget_inside;
    width: 100%;
    background-color: var(--widget-background);
  }
}

@import '../../../styles/utils/variable';
@import '../../../styles/utils/function';

.carousel {
  position: relative;
  width: rem(270);
  min-height: rem(340);
  background-color: var(--widget-background);
  padding: rem(10);
  border-radius: rem(2);
  box-shadow: $carousel_boxShadow;
  overflow: hidden;

  &Container {
    overflow: hidden;
    height: rem(340);
  }

  &Wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    white-space: nowrap;
    height: rem(340);
    transition: transform 300ms ease-out;
  }

  &Item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: rem(340);
    width: rem(250);
    box-shadow: $carousel_boxShadow;

    &Title {
      width: rem(250);
      padding: 0 rem(10);
      color: var(--white);
      line-height: rem(32);
      white-space: normal;
      text-align: left;

      &IsCenter {
        text-align: center;
      }
    }
  }

  &LinkContainer {
    width: 100%;
    padding: 0 rem(10);
    display: flex;
    justify-content: flex-start;

    &IsCenter {
      justify-content: center;
    }
  }

  &Link {
    &IsPromo {
      width: rem(126);
      padding: rem(8) rem(16);
      background-color: var(--warning);
      color: var(--white);
      font-weight: 600;
      line-height: rem(24);
      border-radius: rem(6);
      cursor: pointer;
    }

    &IsTop {
      background-color: transparent;
      color: var(--white);
      font-size: rem(14);
      font-weight: 700;
      line-height: rem(24);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &Footer {
    position: absolute;
    bottom: rem(10);
    width: rem(250);
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      color: var(--white);
    }

    &Arrow {
      &IsInactive {
        svg {
          opacity: 0.25;
        }

        cursor: not-allowed;
      }
    }

    &Dots {
      display: flex;
      align-items: center;
    }

    &Dot {
      display: block;
      width: rem(12);
      height: rem(12);
      margin: 0 rem(8);
      background-color: var(--white);
      border-radius: 50%;
      opacity: 1;

      &IsInactive {
        width: rem(8);
        height: rem(8);
        opacity: 0.6;
      }
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

:export {
  defaultSize: rem(28);
}

.loader {
  display: flex;

  &IsHorizontallyCenter {
    width: 100%;
    flex: 1;
    justify-content: center;
  }
  &IsVerticallyCenter {
    height: 100%;
    flex: 1;
    align-items: center;
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.header {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(8);
  width: 100%;
  flex: 1;

  &InstrumentSearch {
    flex: 1;
  }

  &PriceText {
    @include realtimePrice;

    white-space: nowrap;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 600;
    color: var(--text-primary);
    margin-left: rem(12);
  }

  &ChangeText {
    display: flex;
    align-items: center;

    span {
      @include realtimePrice;

      white-space: nowrap;
      font-size: rem(12);
      line-height: rem(16);
      font-weight: 500;
    }

    &IsPositive {
      span,
      svg {
        color: var(--positive-change);
      }
    }

    &IsNegative {
      span,
      svg {
        color: var(--negative-change);
      }
    }
  }
}

.errorIcon {
  color: var(--error);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.root {
  background-color: var(--input-background);
  // TODO: remove
  border: rem(1) solid var(--input-border) !important;
  padding: 0;
  border-radius: rem(6);

  svg {
    color: var(--input-icon);
  }
}

.nbSelect {
  width: 100%;

  :global(.MuiInputBase-sizeSmall) {
    height: rem(30);
  }
}

:global(.MuiPaper-root.MuiPopover-paper) {
  background-color: var(--popover-background);
  border: rem(1) solid var(--popover-border);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.tabs {
  max-width: inherit;

  button {
    text-transform: inherit;
  }
}

:global(.MuiTabs-root).root {
  max-height: rem(38);
  min-height: rem(38);

  button {
    max-height: rem(38);
    min-height: rem(38);
  }
}

:global(.MuiButtonBase-root.MuiTab-root) {
  padding: 0 rem(10);
}

:global(.MuiTabs-root .MuiButtonBase-root) {
  color: var(--tab-text);
}

:global(.MuiTabs-root .MuiButtonBase-root.Mui-selected) {
  background-color: var(--tab-selected-background);
  color: var(--tab-selected-text);
}

:global(.MuiTabs-root .Mui-disabled) {
  width: 0;
  transition: 0.1s ease-in;
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.container {
  position: fixed;
  z-index: 1001;
  bottom: rem(10);
  left: rem(10);
  right: rem(10);
  height: 30vh;
  min-height: rem(300);
  background: var(--widget-background);
  border: rem(2) solid var(--purple100);
  padding: rem(10);
  border-radius: rem(6);
}

.infoBoxWrapper {
  display: flex;
  flex-direction: row;
  gap: rem(20);
  width: 100%;
  height: 100%;
}

.loggerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  &Header {
    display: flex;
  }

  textarea {
    font-family: monospace;
    font-size: rem(12);
    width: 100%;
    height: 100%;
  }
}

.debugDataWrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: rem(12);
}

.debugData {
  width: 100%;
  height: 100%;
  padding: rem(10);
  overflow: scroll;

  .debugItem {
    display: flex;
    flex-direction: row;
    margin-bottom: rem(4);

    &Key {
      margin-right: rem(2);
      font-weight: 500;
      color: darkmagenta;

      &:after {
        content: ':';
      }
    }

    &Value {
      margin-left: rem(2);
    }
  }
}

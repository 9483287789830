@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 0;

  button {
    margin: 0 rem(20);
    padding: rem(4);

    svg {
      color: var(--widget-header-icon);
    }
  }

  &More {
    // sadly necessary important to have the button positioned absolutely
    position: absolute !important;
    right: rem(-6);
    top: rem(2);
    z-index: 2;
  }
}

.cell {
  &BuyType {
    color: var(--buy);
  }

  &SellType {
    color: var(--sell);
  }
}

.actions {
  &Notes {
    svg {
      color: var(--info);
    }
  }

  &Order {
    svg {
      color: var(--error);
    }
  }
}

.green {
  color: var(--positive-change-light);
}

.popover {
  @include setColumnsPopover;
}

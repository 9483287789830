@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap');

body * {
  font-family: map-get($typo, 'font'), sans-serif;
}

body {
  font-size: rem(map-get($typo, 'size'));
}

h1 {
  margin: rem(20) 0;
  line-height: rem(60);
  font-size: rem(48);
  font-weight: 700;
}

h2 {
  margin: rem(16) 0;
  line-height: rem(40);
  font-size: rem(32);
  font-weight: 700;
}

h3 {
  margin: rem(12) 0;
  line-height: rem(30);
  font-size: rem(24);
  font-weight: 700;
}

h4 {
  margin: rem(8) 0;
  line-height: rem(26);
  font-size: rem(20);
  font-weight: 400;
}

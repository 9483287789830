@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.alerts {
  position: relative;
}

.header {
  padding: $padding_widget_inside;

  .MuiFormControl-root {
    width: rem(100);
  }
}

.popover {
  @include setColumnsPopover;
}

.more {
  position: absolute;
  right: rem(12);
  z-index: 2;

  svg {
    color: var(--text-primary);
  }
}

.actions {
  &Edit {
    svg {
      color: var(--blue);
    }
  }

  &Delete {
    svg {
      color: var(--error);
    }
  }
}

@import './function';
@import './variable';
@import './mixin';

.MuiPaper-root.MuiPopover-paper {
  box-shadow: $popover_boxShadow;
}

.MuiPaper-root.MuiDialog-paper {
  max-width: 100vw;
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.orderLog {
  position: relative;
  height: 100%;
  display: flex;
  overflow-y: scroll;

  :global(.MuiDataGrid-main) {
    margin-bottom: rem(55);
  }

  :global(.MuiDataGrid-root.MuiDataGrid-autoHeight) {
    border-color: transparent;
  }

  &Wrapper {
    overflow-y: scroll;
  }
}

.selects {
  display: flex;
  gap: rem(10);
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: rem(1) solid var(--widget-divider);
  padding: rem(10) rem(15) rem(10);
  width: 100%;
  background-color: var(--widget-background);

  :global(.MuiFormControl-root) {
    width: rem(140);
  }
}

.select {
  width: rem(140);
}

:global(.MuiInputBase-root).select {
  width: rem(140);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

$column_width: rem(320);

.orderForm {
  &Content {
    max-width: rem(650);
    min-width: rem(350);
    padding: 0;
    border-top: rem(1) solid var(--modal-divider);
    border-bottom: rem(1) solid var(--modal-divider);

    &IsWidget {
      margin: 0;
      padding: 0;

      border-top: none;
      border-bottom: none;
    }
  }

  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(4) 0;

    &IsDisabled {
      p {
        color: var(--text-disabled);
        opacity: 0.5;
      }
    }

    &Col {
      display: flex;
      width: $column_width;
    }

    &Title {
      min-width: rem(150);
      color: var(--text-primary);
      font-size: rem(12);
      font-weight: 600;
      line-height: rem(16);
      margin: 0;
    }

    &Select {
      width: $column_width;
    }

    &Inputs {
      display: flex;
      justify-content: space-between;

      button {
        width: rem(96);
      }
    }

    &Quantity {
      margin-right: rem(10);
    }

    &Buttons {
      display: flex;
      width: $column_width;

      &IsWidget {
        width: 100%;
      }

      button {
        width: 100%;
      }

      button:first-of-type {
        margin-right: rem(16);
      }
    }

    &SellButton {
    }

    &BuyButton {
    }

    &Input {
      width: $column_width;
    }
  }

  &Switch {
    padding-top: rem(16);
  }

  &SwitchLabel {
    padding-left: rem(20);
    color: var(--text-disabled);
    font-size: rem(12);
    font-weight: 600;
    line-height: rem(16);
  }

  &Buttons {
    display: flex;
    justify-content: space-between;
    padding-top: rem(10);
  }
}

:global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary).orderFormRowSellButton {
  background: var(--button-sell);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedPrimary).orderFormRowSellButton {
  color: var(--button-sell);
  border-color: var(--button-sell);
}

:global(.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary).orderFormRowBuyButton {
  background: var(--button-buy);
}
:global(.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedPrimary).orderFormRowBuyButton {
  color: var(--button-buy);
  border-color: var(--button-buy);
}

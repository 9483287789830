@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.item {
  display: flex;
  align-items: center;
  width: 100%;

  &Name {
    flex: 1;
  }

  button {
    padding: 0;

    svg {
      height: rem(20);
    }
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';

.modal {
  display: flex;
  flex-direction: column;
  width: rem(540);
  padding: rem(16) rem(24);

  color: var(--text-primary);
}

:global(.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary).isDanger {
  background-color: var(--error);
}

:global(.MuiPaper-root.MuiDialog-paper) {
  background-color: var(--modal-background);
}

:global(.MuiDialogActions-root).buttons {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

:global(.MuiDialogTitle-root).title {
  display: flex;
  justify-content: space-between;
  padding: 0;
  font-size: rem(18);
  font-weight: 700;
  line-height: rem(28);

  svg {
    width: rem(20);
    height: rem(20);
    color: var(--text-primary);
    cursor: pointer;
  }
}

:global(.MuiDialogContent-root).content {
  margin-top: rem(30);
  padding: 0;
}

:global(.MuiDialogContent-root) .text {
  color: var(--text-primary);
  margin-bottom: rem(30);
  padding: 0;
  font-size: rem(14);
  font-weight: 400;
  line-height: rem(20);
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.popover {
  @include basePopover();
}

.actions {
  display: flex;
  flex-direction: column;
  background-color: var(--popover-background);

  button {
    justify-content: left;
    color: var(--text-primary);
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
    text-align: left;
  }

  svg {
    color: var(--primary);
  }
}

.header {
  display: flex;
  justify-content: space-between;

  &Tabs {
    height: rem(38);
  }

  &Actions {
    display: flex;
    max-height: rem(38);
    min-height: rem(38);

    svg {
      color: var(--modal-header-text);
    }
  }
}

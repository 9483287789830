@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

$widget_gap: $padding_widget_between;

:export {
  widgetGap: $widget_gap;
}

.widgetView {
  flex: 1;
  display: flex;
  gap: $widget_gap;
  max-height: 100%;
  height: 100%;
  background-color: var(--background);

  :global {
    .MuiPaper-root {
      box-shadow: $widget_boxShadow;
      border-radius: rem(2);
      background-color: var(--widget-background);
    }
  }

  &Column {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: $widget_gap;
    max-height: 100%;
  }

  &Row {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: $widget_gap;
    max-width: 100%;
  }
}

@import '../../../styles/utils/function';
@import '../../../styles/utils/variable';
@import '../../../styles/utils/mixin';

.edit {
  box-shadow: $general_boxShadow;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(38);
    padding: 0 rem(4);
    background-color: var(--widget-background);
    border-bottom: rem(1) solid var(--border);

    &Inputs {
      display: flex;
      align-items: center;
      gap: rem(6);

      svg {
        color: var(--text-primary);
      }
    }

    &Buttons {
      button {
        margin-left: rem(6);
      }
    }
  }
}

.textField {
  width: rem(170);
}

.autocomplete {
  width: rem(180);

  :global {
    .MuiInputBase-root {
      height: rem(30);
      border-radius: rem(6);
    }
  }

  :global {
    .MuiAutocomplete-input {
      padding: rem(5) rem(4) rem(5) rem(2) !important;
    }
  }
}

.popover {
  @include basePopover();
}

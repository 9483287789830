html {
  height: 100%;
  // font-size is set in responsive-layout.scss
  //font-size: map-get($typo, 'size') * 1px;
}

body,
body * {
  box-sizing: border-box;
}

body {
  position: relative;
  height: 100vh;
  color: var(--text-primary);
  background-color: var(--background);

  > #root {
    height: 100%;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}
